
import {Component, Prop, Vue} from 'vue-property-decorator';
import {email, password, required} from '@/utils/validationRules';
import IRegistrationForm from '@/types/IRegistrationForm';
import ICountry from '@/types/ICountry';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'RegistrationForm'})
export default class RegistrationForm extends Vue {
  @Prop()
  countries!: ICountry[];

  @Prop()
  onSubmit!: Function;

  loading: boolean = false;

  form: IRegistrationForm = {
    companyName: '',
    firstName: '',
    lastName: '',
    mail: '',
    password: '',
    confirmPassword: '',
    address: '',
    city: '',
    countryId: 0,
    postalCode: '',
  };

  rules = {
    companyName: [required],
    firstName: [required],
    lastName: [required],
    mail: [required, email],
    password: [required, password],
    confirmPassword: [required, this.confirmPassword],
    address: [required],
    city: [required],
    countryId: [required],
    postalCode: [required],
  };

  confirmPassword(value: string) {
    return value === this.form.password || this.$t('SYSTEM_ENTERED_PASSWORDS_MUST_BE_THE_SAME');
  }

  async register() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await this.onSubmit(this.form);
        notify(NotificationTypes.success, this.$t('SYSTEM_ACCOUNT_HAS_BEEN_REGISTERED'));
        await this.$router.push({name: 'Login'});
      } catch (e) {
        resolveError(e, 'register');
      } finally {
        this.loading = false;
      }
    }
  }
}
