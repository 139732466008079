
import {Component, Vue} from 'vue-property-decorator';
import RegistrationForm from '@/components/RegistrationForm.vue';
import IRegistrationForm from '@/types/IRegistrationForm';
import AuthService from '@/services/AuthService';
import ICountry from '@/types/ICountry';
import {resolveError} from '@/utils/notifications';
// import LoginForm from '@/components/LoginForm.vue';

@Component({
  name: 'Registration',
  components: {RegistrationForm},
  // components: {LoginForm}
})
export default class Registration extends Vue {
  countries: ICountry[] = [];

  mounted() {
    this.fetchCountries();
  }

  async fetchCountries() {
    try {
      this.countries = await AuthService.getRegisterForm();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  register(data: IRegistrationForm) {
    return AuthService.register(data);
  }
}
